import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import HomeDesktop from "../../images/projects/auris/home--desktop.mp4"
import ProjectCard from "../../components/project-card"
import Labgrown from "../../images/projects/labgrown/cover.jpg"
import GrownAs from "../../images/projects/grownas/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Auris" />

<WorkInfo 
  title="Auris"
  link="theauris.com" 
  team1="Bailey Latimer"
  challenge="It’s not hyperbole to say Auris has fans, not just customers. They’ve been thoroughly embraced by audiophiles around the world. Auris asked me to help craft a brand story and custom Shopify theme that would embody the sound of the past, built for the future."
  solution="In true Auris fashion, we created a moodboard with nostalgic elements that felt like an 70's record store. Through this inspiration, I build a brand identity and design system that would direct the overall mood of the website. Next, I developed the custom theme on Shopify 2.0 in 3 weeks."/>


    <video class="mb-6"  
      loop
      muted
      autoPlay
      playsinline>
      <source src={HomeDesktop} type="video/mp4" />
    </video>
      <StaticImage class="mb-6" src="../../images/projects/auris/cover.jpg" alt="Auris Hero" aspectRatio={16/7} layout={"fullWidth"}/>
      <StaticImage class="mb-6" src="../../images/projects/auris/inspo.jpg" alt="Auris Moodboard" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/auris/browser.jpg" alt="Auris Browser Preview" layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/auris/menu--mobile.jpg" alt="Auris Mobile Menu" layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/auris/home--desktop.jpg" alt="Auris Home Preview" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/auris/about--mobile.jpg" alt="Auris Mobile Preview" layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/auris/review--mobile.jpg" alt="Auris Mobile Reviews" layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/auris/about--desktop.jpg" alt="Auris Moodboard" layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/auris/logo--before.jpg" alt="Auris Logo Before"  layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/auris/logo--after.jpg" alt="Auris Logo After"  layout={"fullWidth"}/>
      </div>

      <StaticImage class="mb-20"src="../../images/projects/auris/brand-01.jpg" alt="Auris Typography" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="GrownAs Mac" url="grownas-mac"  description="Motion-focused e-commerce experience for the cheesiest vegan mac available. Developed on Shopify in a week." cover={GrownAs}  role="Design + Development"/>

          <ProjectCard classes="" title="Labgrown" url="labgrown"  description="Creating an e-commerce experience to position labgrown as a pioneer in the sustainable jewelry market." cover={Labgrown}  role="Design + Development + Branding"/>
        </ul>
      </div>
  </Layout>
)

export default aurisPage
